// 微信支付

import { Toast } from 'vant';

export default function (payInfo, successCB, errorCB, vm) {
  function onBridgeReady() {
    WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": payInfo.appId, //公众号ID，由商户传入    
        "timeStamp": payInfo.timeStamp, //时间戳，自1970年以来的秒数    
        "nonceStr": payInfo.nonceStr, //随机串    
        "package": payInfo.prepay_id,
        "signType": payInfo.signType || "RSA", //微信签名方式：    
        "paySign": payInfo.paySign //微信签名
      },
      function (res) {
        console.log("__DEBUG__:res:", res);
        // successCB && successCB.apply(vm,[res]);
        // return;
        // 接入微信支付后放开下面代码
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          successCB && successCB.apply(vm,[res]);
        } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
          errorCB && errorCB.apply(vm,[new Error('已取消支付')]);
        } else {
          errorCB && errorCB.apply(vm,[new Error('已取消支付')]);
        }
      });
  }
  if (typeof WeixinJSBridge == "undefined") {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
      document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
    }
  } else {
    onBridgeReady();
  }
}