/**
 * 电费账务相关接口
 * 微服务： 电费账务(psdmsdfzw)
 */

import fetch from '@/utils/fetch';

/* ---
微信支付（生成预付单）
*/
export function ydzfService_wxzf(prePayInfo, meta) {
	return fetch({
		// url : 'psdmsdfzw/service/YdzfService/wxzf',
		url : 'psdmshlwjk/pay/payOrder',
		method : 'post',
		data : prePayInfo
	})
}

/* ---
查询用户欠费信息
*/
export function counterFeesService_getUserArrearsInfo(yhbh,isTyjs,jgbm, meta) {
	return fetch({
		url : 'psdmsdfzw/service/CounterFeesService/getUserArrearsInfo',
		method : 'post',
		data : {
			param : {
				yhbh : yhbh, //String
				isTyjs : isTyjs, //boolean
				jgbm : jgbm //String
			}
		}
	})
}